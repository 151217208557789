<template>
  <div class="main-container">
    <!-- title -->
    <div class="title">
      <span class="title-text">{{ title }}</span>
      <!-- bottom button -->
      <div class="bottom-button">
        <el-button @click="goBackTo">返回</el-button>
      </div>
    </div>
    <!-- type option  -->
    <div class="type-option">
      <template v-for="(option, index) in typeOptions">
        <div v-if="option.show"
          @click="optionClick(option.type)"
          :key="index"
          class="option-item"
          :style="`backgroundImage:url(${option.backgroundImageUrl});
        backgroundColor:${option.backgroundColor};--shadow-color:${
          option.shadowColor || '#333333'
        }`">
          <div class="option-left">
            <div class="option-left-title">{{ option.optionTitle }}</div>
            <div class="option-left-content">{{ option.content }}</div>
          </div>
          <div class="common option-right">
            <img :src="`${option.optionIconUrl}`"
              class="icon-image"
              :style="`--shadow-color2:${option.iconBgColor}`" />
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getPageConfig } from '@/views/CorrectionSheetManagement/pagesConfig.js';
export default {
  name: "CreateCorrection",
  data() {
    return {
      title: "请选择要变更的信息类型",
      typeOptions: [
        {
          show: true,
          shadowColor: "#D2E6FFFF",
          backgroundImageUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/b1.png"),
          optionIconUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/i1.png"),
          optionTitle: "人员信息变更",
          content: "更改被保险人、投保人、受益人信息",
          backgroundColor: "#F6FAFFFF",
          iconBgColor: "#3ABEE5FF",
          type: "1",
        },
        {
          show: true,
          shadowColor: "#F7EDFFFF",
          backgroundImageUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/b2.png"),
          optionIconUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/i2.png"),
          optionTitle: "标的信息",
          content: "更改财产地址、标的、更改保额等信息",
          backgroundColor: "#FBF6FFFF",
          iconBgColor: "#919DFBFF",
          type: "2",
        },
        {
          show: true,
          shadowColor: "#FFEBDBFF",
          backgroundImageUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/b3.png"),
          optionIconUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/i3.png"),
          optionTitle: "保险信息",
          content: "更改保险期限、费率、保险责任、扩大保险责任等信息",
          backgroundColor: "#FFFAF6FF",
          iconBgColor: "#FFADC1FF",
          type: "3",
        },
        {
          show: true,
          shadowColor: "#DFFFE3FF",
          backgroundImageUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/b4.png"),
          optionIconUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/i4.png"),
          optionTitle: "其它信息",
          content: "更改退保、注销、免赔约定、特别约定等信息",
          backgroundColor: "#F6FFF7FF",
          iconBgColor: "#95FBC5FF",
          type: "4",
        },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const pageConfig = getPageConfig(this.$route.meta.policyType);
      if (pageConfig?.changeCreateCorrectionIndex) {
        pageConfig.changeCreateCorrectionIndex.forEach((item, i) => {
          this.typeOptions[item - 1] = pageConfig.changeItem[i];
        });
      }
    },
    optionClick(value) {
      let orderId = this.$route.params.orderId;
      if (!orderId) {
        this.$message({
          message: "无法获取对应数据！",
          type: "error",
        });
        return;
      }
      this.$router.push({
        name: `correctionEdit${ this.$route.meta.policyType }`,
        params: {
          approvalType: value,
          orderId: orderId,
        },
      });
    },
    goBackTo() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
}

.title {
  display: flex;
  align-items: center;
  padding: 24px;
  position: relative;
  .title-text {
    font-weight: bold;
    color: #333333;
    font-size: 18px;
  }
}

.title::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 24px;
  background: #4278c9;
  border-radius: 20px 4px 4px 20px;
  opacity: 1;
  margin-right: 14px;
}

.type-option {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  .option-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    width: 480px;
    height: 96px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin: 0 64px 32px 0;

    &:hover {
      box-shadow: 0 10px 10px var(--shadow-color);
    }

    .option-left {
      display: column;

      > div {
        padding: 8px 0 0 24px;
        text-align: left;
      }

      .option-left-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
      }

      .option-left-content {
        font-size: 14px;
        font-weight: 400;
        color: #666666ff;
        line-height: 21px;
      }
    }

    .option-right {
      > i {
        padding: 0 12px 0 29px;
      }
    }

    > img {
      width: 48px;
    }
  }
}
.bottom-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
  position: absolute;
  right: 0;
}
.common {
  display: flex;
  align-items: center;
}
.icon-image {
  filter: drop-shadow(0 10px 5px var(--shadow-color2));
}
</style>
